/* Interaction types */

.interaction-type {
  padding: 1rem;

  background-color: #f6f6f6;
  border: 1px solid grey;
}

.interaction-type__title {
  text-align: center;
  margin-bottom: 2rem;
}

.interaction-type__overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  color: #fff;

  background-color: rgba(131, 186, 93, 0.8);

  transition: 0.15s ease-in-out all;
}

.interaction-type__overlay--explanation {
  opacity: 1;
  visibility: visible;

  text-align: center;

  [data-started] & {
    opacity: 0;
    visibility: hidden;
  }
}

.interaction-type__overlay--response {
  opacity: 0;
  visibility: hidden;

  [data-sent] & {
    opacity: 1;
    visibility: visible;
  }
}

.interaction-type__overlay-title {
  margin-bottom: 1rem;
}
