$slider-input-thumb-length: 2rem;

$slider-transition: ease-in 0.2s;

@keyframes pulse_animation {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 2px 1px $white;
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 4px 1px $white;
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 2px 1px $white;
  }
}

.slider {
  width: 100%;
  position: relative;

  flex-grow: 1;

  display: flex;
  flex-flow: column nowrap;
}

.slider__list {
  background-color: transparentize($white, 0.9);

  list-style: none;
  margin: 0;

  line-height: 1.1;
  text-align: right;

  width: 100%;
  height: 100%;

  flex-grow: 1;

  display: flex;
  flex-flow: column nowrap;
}

.slider__list-item {
  flex-grow: 1;

  height: 1.2em;
  padding: 1rem 0.25rem;
  width: calc(100% - #{$icon-button-length * 2.5});

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.slider__answer-text {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 150%;
  max-height: 4px;

  font-family: $font-headings;

  transition: all $slider-transition;
  transform-origin: right center;

  transform: scale(0.5);

  &::before,
  &::after {
    content: '';
    height: 2px;
    width: 100%;

    position: absolute;
    right: 0;

    background-color: $white;

    transition: all $slider-transition;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  .slider__list-item--active & {
    transform: scale(1);
    max-height: 2em;
    border-width: 0;

    &::before,
    &::after {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.slider__answer-text--hint {
  .slider__list-item--active & {
    max-height: 2.4em;
  }
}

.slider__answer-hint {
  display: block;
  font-size: 0.9375rem;
  font-family: $font-main;
}

.slider__selector {
  position: absolute;
  left: 0;
  top: 50%;

  display: flex;
  justify-content: flex-end;

  width: 100%;
  height: $icon-button-length;

  margin-top: (-$icon-button-length / 2);

  &::after,
  &::before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;

    position: absolute;
    right: $icon-button-length * 2;
    margin-right: -1.5rem;

    background: url(img/slider-arrow.svg) no-repeat center / contain;

    opacity: 0;
    visibility: hidden;

    transition: $slider-transition;
  }

  &::before {
    bottom: 0.5rem;
    transform: rotate(180deg);
  }

  &::after {
    top: 0.5rem;
  }

  &.init,
  &.active {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
    }

    &::before {
      bottom: $icon-button-length + 0.5rem;
    }

    &::after {
      top: $icon-button-length + 0.5rem;
    }
  }
}

.slider__thumb {
  background-color: transparentize($canary, 0.5);
  border-radius: 50%;

  width: $icon-button-length;
  height: $icon-button-length;
  margin-right: 0.5rem;

  transform: scale(1);
  transition: $slider-transition;
  transition-property: transform, background-color;

  .init & {
    background-color: $canary;

    transition-property: background-color;
    animation: pulse_animation 2s infinite ease-in;
  }

  .active & {
    background-color: transparentize($canary, 0.9);
  }
}

.slider__submit {
  padding: 0;
  margin-right: 0.5rem;

  visibility: hidden;
  opacity: 0;

  transform: translateX(#{-$icon-button-length - 0.5rem});

  transition: $slider-transition;
  transition-property: opacity, visibility, transform;

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
}
