@font-face {
  font-family: 'Helvetica Neue LT';
  src: url(./fonts/HelveticaNeueLTPro-Lt_0.ttf);
  font-weight: normal;
}

@font-face {
  font-family: 'Helvetica Rounded LT';
  src: url(./fonts/HelveticaRoundedLTStd-Bd.ttf);
  font-weight: bold;
}
