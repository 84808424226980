.messages {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  background-color: rgba(50, 50, 50, 0.75);
  z-index: 9;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.messages__message {
  text-align: center;
  padding: 0.5rem 1rem;

  color: $white;

  p {
    margin-bottom: 0;
  }
}
