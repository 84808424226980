/* Button list / grid */

.image-grid {
  list-style: none;
  margin: 0 0 4rem 0;
  padding: 0 0 5rem;

  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.image-grid__item {
  width: 50%;
  margin-bottom: 0;

  padding: 0.125rem;
}

.image-grid__button {
  display: block;
  position: relative;

  width: 100%;
  height: 100%;

  padding: 0;
  padding-top: 100%;

  color: $black;
  text-decoration: none;

  background-color: $white;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 4px solid $canary;

    opacity: 0;
    visibility: hidden;

    transition: 0.2s ease-in-out;
    transition-property: opacity visibility;
  }
}

.image-grid__button--selected {
  &:after {
    opacity: 1;
    visibility: visible;
  }
}

.image-grid__image {
  position: absolute;
  top: 50%;
  left: 50%;

  max-height: 100%;
  transform: translate(-50%, -50%);
}
