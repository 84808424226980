// General elements
// ================

p {
  margin-bottom: 1rem;
}

a {
  text-decoration: underline;
}

/* Buttons */

.button-wrapper {
  display: flex;
  justify-content: space-between;
}

.button {
  display: inline-block;
  color: $punga;
  text-decoration: none;
  background-color: $canary;
  font-family: $font-headings;
  padding: 0.3rem 1rem 0.2rem;

  border-radius: 18.25px;
}

button.button {
  border: none;
  cursor: pointer;
}

$icon-button-length: 2.8125rem; // 4.0625rem 65 / 2.8125rem 45

.icon-button {
  display: block;
  width: $icon-button-length;
  height: $icon-button-length;

  transition: 0.2s ease;
  transition-property: opacity, visibility;

  svg {
    width: 100%;
    height: 100%;
  }
}

.icon-button--disabled {
  opacity: 0.5;
}

.icon-button--hide {
  opacity: 0;
  visibility: hidden;
}

.icon-button--ar {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon {
  width: 65px;
}

// Main content
// ============

.content {
  max-width: 40rem;
  overflow: hidden;

  margin-left: auto;
  margin-right: auto;

  box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.3);
}

// AR
// ==

body.ar {
  overflow: hidden;
}

// Form fields
// ===========

input[type='text'],
input[type='number'] {
  appearance: none;
  display: block;
  padding: 0.9rem 0.5rem 0.6rem;
  margin-bottom: 1rem;

  background-color: $white;
  border: none;
  border-radius: 0;
  box-shadow: inset 2px 2px 4px 0 transparentize($black, 0.7);

  text-align: center;
  font-size: 1.25rem;
  line-height: 1;
}

// Start: Terms
// ============

.terms__checkbox {
  appearance: none;
  position: relative;

  width: 1.5rem;
  height: 1.5rem;

  display: block;
  margin: 0 auto 1rem;

  border-radius: 4px;
  background-color: $white;
  border: 4px solid $black;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 70%;
    height: 70%;

    border-radius: 2px;
    background-color: $black;
    visibility: hidden;
    opacity: 0;

    transition: 0.1s ease;
    transition-property: visibility, opacity;
  }

  &:checked {
    &::after {
      visibility: visible;
      opacity: 1;
    }
  }
}

.terms__label {
  font-size: 1rem;
}
