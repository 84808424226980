$swiper-max-width-small: 12rem;
$swiper-max-width: 15rem;

@keyframes swipe-left {
  to {
    transform: rotate(-13deg) translate3d(-100%, 0, 0);
    opacity: 0;
  }
}

@keyframes swipe-right {
  to {
    transform: rotate(13deg) translate3d(100%, 0, 0);
    opacity: 0;
  }
}

@keyframes swipe-fade-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes swipe-bg-zoom {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(0.7);
    opacity: 0.2;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.swiper {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.swiper__slide {
  margin: 0 auto;
  max-width: $swiper-max-width;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  animation: swipe-fade-in 0.4s ease-out;

  box-shadow: 0 3px 5px 0 transparentize($black, 0.5);

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .animation-left & {
    animation: swipe-left 0.5s ease-out forwards;
  }

  .animation-right & {
    animation: swipe-right 0.5s ease-out forwards;
  }

  @media #{$small-devices} {
    max-width: $swiper-max-width-small;
  }
}

.swiper__slide--empty {
  box-shadow: none;
  animation: none;
  visibility: hidden;
}

.swiper__background {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .animation-left &,
  .animation-right & {
    animation: swipe-bg-zoom 1s ease-in forwards;
    animation-delay: 0.3s;
  }

  .animation-left & {
    svg path {
      fill: $sunset-orange;
    }
  }

  .animation-right & {
    svg path {
      fill: $canary;
    }
  }
}

.swiper-button-group {
  display: flex;
  justify-content: center;
  width: 100%;

  & > * {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
