.footer {
  padding: 2rem 0;

  visibility: visible;
  opacity: 1;

  transition: all 0.2s ease;

  .icon-button {
    margin: 0 auto;
  }
}

.footer--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  /* Rectangle: */
  background-image: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6)
  );
}

.footer--hidden {
  visibility: hidden;
  opacity: 0;
}
