.buzzer {
  position: relative;
  padding: 1rem;
}

.buzzer__countdown {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: none;
  justify-content: center;
  align-items: center;

  background-color: transparentize($black, 0.25);
  color: $white;

  font-weight: bold;
  font-size: 2rem;

  [data-started] & {
    display: flex;
  }
}

.buzzer__button {
  display: block;
  margin: 0 auto;

  background-color: red;
  box-shadow: 0 0 4px red;

  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  color: $white;
  font-weight: bold;
  text-align: center;
}
