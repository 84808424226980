/* Button list */

.button-list {
  list-style: none;
  margin: 0;
  padding: 0;

  width: 100%;
}

.button-list__item {
  margin-bottom: 1rem;
}

.button-list__button {
  display: block;
  color: $punga;
  text-decoration: none;
  background-color: $canary;
  font-family: $font-headings;
  padding: 0.3rem 1rem 0.2rem;
  width: 100%;

  border-radius: 18.25px;

  line-height: 1.2;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
}
