@font-face {
  font-family: 'Helvetica Neue LT';
  src: url(./fonts/HelveticaNeueLTPro-Lt_0.ttf);
  font-weight: normal;
}

@font-face {
  font-family: 'Helvetica Rounded LT';
  src: url(./fonts/HelveticaRoundedLTStd-Bd.ttf);
  font-weight: bold;
}

$font-main: 'Helvetica Neue LT', sans-serif;
$font-headings: 'Helvetica Rounded LT', sans-serif;

$body-font-size: 1.125rem;
$body-line-height: 1.5;
$headings-line-height: 1.09;

$h1-font-size: 4rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.5rem;

%heading-font-styles {
  margin-bottom: 1rem;
  line-height: $headings-line-height;
  font-weight: normal;
  font-family: $font-headings;
}

%h1-font-size {
  font-size: 3.5rem;
}

%h2-font-size {
  font-size: 2.8rem;
}

%h3-font-size {
  font-size: 2rem;
}

h1,
h2,
h3,
h4 {
  @extend %heading-font-styles;
}

h1 {
  @extend %h1-font-size;
}

h2 {
  @extend %h2-font-size;
}

h3 {
  @extend %h3-font-size;
}

small {
  font-size: 0.875rem;
}

body {
  font-family: $font-main;
  font-size: $body-font-size;
  line-height: $body-line-height;
  color: $white;

  @media #{$small-devices} {
    font-size: 1rem;
  }
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: $black;
}

// Light
.text--light {
  line-height: 1.3;
  font-size: $body-font-size;

  @media #{$small-devices} {
    font-size: 1rem;
  }
}

.text--bold {
  font-family: $font-headings;
  line-height: 1.27;
  font-size: 1.125rem;

  @media #{$small-devices} {
    font-size: 1rem;
  }
}

.main-title {
  margin-bottom: 0;
}

.main-title__sub {
  font-size: 1.5rem;
  font-family: $font-main;
  display: block;
  margin-bottom: 1rem;
}
