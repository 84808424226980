.photo-upload {
  width: 100%;
  min-height: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  // .icon-button {
  //   margin-bottom: 1rem;
  // }

  .loading-indicator {
    margin-bottom: 1rem;
  }
}

.photo-upload--preview {
  flex-grow: 1;
}

.photo-upload__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.photo-upload__preview {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
  min-height: 1rem;
  width: 100%;
  flex-grow: 1;

  background: no-repeat center / contain;
}

.photo-upload__buttons {
  width: 100%;
  display: flex;
  justify-content: center;

  & > * {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.photo-upload__skip {
  position: fixed;
  bottom: 1rem;

  .photo-upload--preview & {
    position: static;
    margin-top: 1rem;
    margin-bottom: -1rem;
  }
}
