.agb {
  padding: 1rem;
  color: $black;

  h1 {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.125rem;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    font-family: $font-main;
  }

  ul {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
}
