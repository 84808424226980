.image-upload {
  input {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.image-upload__preview {
  display: block;

  max-width: 100%;
  margin-bottom: 1rem;
}
