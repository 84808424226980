@keyframes blur-out {
  from {
    filter: blur(0);
  }
  to {
    filter: blur(4px);
  }
}

.screen {
  display: flex;
  flex-flow: column nowrap;

  position: relative;
}

.screen--ar {
  .screen__skip-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    background-color: $black;
    padding: 0.5rem 1rem;

    font-size: 1rem;

    a {
      margin-left: 1rem;
    }
  }
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(img/background.png) center / cover no-repeat;

  .screen--blur & {
    filter: blur(4px);
    animation: blur-out 1s ease;
  }

  .screen--question & {
    background-image: url(img/background_r.png);
    background-position: bottom left;
  }

  .screen--ar.screen--iframe & {
    background: none;
  }

  .screen--image-grid & {
    background-image: url(img/background_wo_r.png);
  }
}

.screen__icons {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 999;

  a {
    display: block;
    background-color: $black;

    font-size: 3rem;
    line-height: 1;
    text-decoration: none;

    &:hover {
      background-color: lighten($black, 20%);
    }
  }
}

.screen__contents {
  position: relative;
  max-height: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  padding: 1.5rem 2rem;
  text-align: center;

  .screen--start &,
  .screen--map &,
  .screen--shake &,
  .screen--swipe-instructions & {
    justify-content: space-around;
  }

  .screen--image-grid & {
    padding-left: 0;
    padding-right: 0;

    height: 100%;
    self-align: flex-start;
    justify-content: flex-start;

    overflow: auto;
    -webkit-overflow-scrolling: touch;

    p {
      padding: 0 2rem;
    }
  }

  .screen--ar & {
    padding: 0;
    height: 100%;
  }

  // Leave this for the moment
  // .screen--slider & {
  //   padding-left: 1rem;
  //   padding-right: 1rem;
  // }
}

.link--full {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.screen--shake {
  .icon--shake {
    width: 4rem;
    margin-bottom: 1rem;
  }
}

.screen--swipe-instructions {
  .icon--swipe {
    width: auto;
    margin-bottom: 1rem;
  }
}

.screen--ar {
  iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
}

.question {
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
}

.question__content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
  min-height: 1rem;

  .screen--image-grid & {
    justify-content: flex-start;
  }
}
