.map {
  width: 80%;
}

.map__position {
  fill: $white;
}

.map__position--active {
  fill: $canary;
}

.map__position--disabled {
  fill: $punga;
  opacity: 0.16;
}
