/* http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: 16px/1 sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}

main {
  display: block;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

strong {
  font-weight: bold;
}

a,
button {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
  padding: 0;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

:focus {
  outline: 0;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

/* Blue */
/* Yellow */
/* Gray */
/* Green */
/* Red */
@font-face {
  font-family: 'Helvetica Neue LT';
  src: url(./fonts/HelveticaNeueLTPro-Lt_0.ttf);
  font-weight: normal;
}

@font-face {
  font-family: 'Helvetica Rounded LT';
  src: url(./fonts/HelveticaRoundedLTStd-Bd.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Helvetica Neue LT';
  src: url(./fonts/HelveticaNeueLTPro-Lt_0.ttf);
  font-weight: normal;
}

@font-face {
  font-family: 'Helvetica Rounded LT';
  src: url(./fonts/HelveticaRoundedLTStd-Bd.ttf);
  font-weight: bold;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 1rem;
  line-height: 1.09;
  font-weight: normal;
  font-family: "Helvetica Rounded LT", sans-serif;
}

h1 {
  font-size: 3.5rem;
}


h2 {
  font-size: 2.8rem;
}


h3 {
  font-size: 2rem;
}

small {
  font-size: 0.875rem;
}

body {
  font-family: "Helvetica Neue LT", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #ffffff;
}

@media (max-width: 22.4375em) {
  body {
    font-size: 1rem;
  }
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: #000000;
}

.text--light {
  line-height: 1.3;
  font-size: 1.125rem;
}

@media (max-width: 22.4375em) {
  .text--light {
    font-size: 1rem;
  }
}

.text--bold {
  font-family: "Helvetica Rounded LT", sans-serif;
  line-height: 1.27;
  font-size: 1.125rem;
}

@media (max-width: 22.4375em) {
  .text--bold {
    font-size: 1rem;
  }
}

.main-title {
  margin-bottom: 0;
}

.main-title__sub {
  font-size: 1.5rem;
  font-family: "Helvetica Neue LT", sans-serif;
  display: block;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

a {
  text-decoration: underline;
}

/* Buttons */
.button-wrapper {
  display: flex;
  justify-content: space-between;
}

.button {
  display: inline-block;
  color: #5c3a1b;
  text-decoration: none;
  background-color: #caff62;
  font-family: "Helvetica Rounded LT", sans-serif;
  padding: 0.3rem 1rem 0.2rem;
  border-radius: 18.25px;
}

button.button {
  border: none;
  cursor: pointer;
}

.icon-button {
  display: block;
  width: 2.8125rem;
  height: 2.8125rem;
  transition: 0.2s ease;
  transition-property: opacity, visibility;
}

.icon-button svg {
  width: 100%;
  height: 100%;
}

.icon-button--disabled {
  opacity: 0.5;
}

.icon-button--hide {
  opacity: 0;
  visibility: hidden;
}

.icon-button--ar {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon {
  width: 65px;
}

.content {
  max-width: 40rem;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.3);
}

body.ar {
  overflow: hidden;
}

input[type='text'],
input[type='number'] {
  appearance: none;
  display: block;
  padding: 0.9rem 0.5rem 0.6rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 1.25rem;
  line-height: 1;
}

.terms__checkbox {
  appearance: none;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  margin: 0 auto 1rem;
  border-radius: 4px;
  background-color: #ffffff;
  border: 4px solid #000000;
}

.terms__checkbox::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  border-radius: 2px;
  background-color: #000000;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease;
  transition-property: visibility, opacity;
}

.terms__checkbox:checked::after {
  visibility: visible;
  opacity: 1;
}

.terms__label {
  font-size: 1rem;
}

.footer {
  padding: 2rem 0;
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease;
}

.footer .icon-button {
  margin: 0 auto;
}

.footer--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* Rectangle: */
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}

.footer--hidden {
  visibility: hidden;
  opacity: 0;
}

.navigator {
  display: flex;
  flex-flow: row nowrap;
}

.navigator.transition {
  transition: transform 0.4s ease-in-out;
}

.navigator__page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
  border: 1px solid grey;
}

/* Button list */
.button-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.button-list__item {
  margin-bottom: 1rem;
}

.button-list__button {
  display: block;
  color: #5c3a1b;
  text-decoration: none;
  background-color: #caff62;
  font-family: "Helvetica Rounded LT", sans-serif;
  padding: 0.3rem 1rem 0.2rem;
  width: 100%;
  border-radius: 18.25px;
  line-height: 1.2;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
}

@keyframes swipe-left {
  to {
    transform: rotate(-13deg) translate3d(-100%, 0, 0);
    opacity: 0;
  }
}

@keyframes swipe-right {
  to {
    transform: rotate(13deg) translate3d(100%, 0, 0);
    opacity: 0;
  }
}

@keyframes swipe-fade-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes swipe-bg-zoom {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(0.7);
    opacity: 0.2;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.swiper {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.swiper__slide {
  margin: 0 auto;
  max-width: 15rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: swipe-fade-in 0.4s ease-out;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
}

.swiper__slide::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.animation-left .swiper__slide {
  animation: swipe-left 0.5s ease-out forwards;
}

.animation-right .swiper__slide {
  animation: swipe-right 0.5s ease-out forwards;
}

@media (max-width: 22.4375em) {
  .swiper__slide {
    max-width: 12rem;
  }
}

.swiper__slide--empty {
  box-shadow: none;
  animation: none;
  visibility: hidden;
}

.swiper__background {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.animation-left .swiper__background,
.animation-right .swiper__background {
  animation: swipe-bg-zoom 1s ease-in forwards;
  animation-delay: 0.3s;
}

.animation-left .swiper__background svg path {
  fill: #fc4f4f;
}

.animation-right .swiper__background svg path {
  fill: #caff62;
}

.swiper-button-group {
  display: flex;
  justify-content: center;
  width: 100%;
}

.swiper-button-group > * {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

/* Interaction types */
.interaction-type {
  padding: 1rem;
  background-color: #f6f6f6;
  border: 1px solid grey;
}

.interaction-type__title {
  text-align: center;
  margin-bottom: 2rem;
}

.interaction-type__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #fff;
  background-color: rgba(131, 186, 93, 0.8);
  transition: 0.15s ease-in-out all;
}

.interaction-type__overlay--explanation {
  opacity: 1;
  visibility: visible;
  text-align: center;
}

[data-started] .interaction-type__overlay--explanation {
  opacity: 0;
  visibility: hidden;
}

.interaction-type__overlay--response {
  opacity: 0;
  visibility: hidden;
}

[data-sent] .interaction-type__overlay--response {
  opacity: 1;
  visibility: visible;
}

.interaction-type__overlay-title {
  margin-bottom: 1rem;
}

.buzzer {
  position: relative;
  padding: 1rem;
}

.buzzer__countdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  font-weight: bold;
  font-size: 2rem;
}

[data-started] .buzzer__countdown {
  display: flex;
}

.buzzer__button {
  display: block;
  margin: 0 auto;
  background-color: red;
  box-shadow: 0 0 4px red;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

@keyframes pulse_animation {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 2px 1px #ffffff;
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 4px 1px #ffffff;
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 2px 1px #ffffff;
  }
}

.slider {
  width: 100%;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
}

.slider__list {
  background-color: rgba(255, 255, 255, 0.1);
  list-style: none;
  margin: 0;
  line-height: 1.1;
  text-align: right;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
}

.slider__list-item {
  flex-grow: 1;
  height: 1.2em;
  padding: 1rem 0.25rem;
  width: calc(100% - 7.03125rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.slider__answer-text {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 150%;
  max-height: 4px;
  font-family: "Helvetica Rounded LT", sans-serif;
  transition: all ease-in 0.2s;
  transform-origin: right center;
  transform: scale(0.5);
}

.slider__answer-text::before, .slider__answer-text::after {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  right: 0;
  background-color: #ffffff;
  transition: all ease-in 0.2s;
}

.slider__answer-text::before {
  top: 0;
}

.slider__answer-text::after {
  bottom: 0;
}

.slider__list-item--active .slider__answer-text {
  transform: scale(1);
  max-height: 2em;
  border-width: 0;
}

.slider__list-item--active .slider__answer-text::before, .slider__list-item--active .slider__answer-text::after {
  visibility: hidden;
  opacity: 0;
}

.slider__list-item--active .slider__answer-text--hint {
  max-height: 2.4em;
}

.slider__answer-hint {
  display: block;
  font-size: 0.9375rem;
  font-family: "Helvetica Neue LT", sans-serif;
}

.slider__selector {
  position: absolute;
  left: 0;
  top: 50%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 2.8125rem;
  margin-top: -1.40625rem;
}

.slider__selector::after, .slider__selector::before {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 5.625rem;
  margin-right: -1.5rem;
  background: url(img/slider-arrow.svg) no-repeat center/contain;
  opacity: 0;
  visibility: hidden;
  transition: ease-in 0.2s;
}

.slider__selector::before {
  bottom: 0.5rem;
  transform: rotate(180deg);
}

.slider__selector::after {
  top: 0.5rem;
}

.slider__selector.init::before, .slider__selector.init::after, .slider__selector.active::before, .slider__selector.active::after {
  opacity: 1;
  visibility: visible;
}

.slider__selector.init::before, .slider__selector.active::before {
  bottom: 3.3125rem;
}

.slider__selector.init::after, .slider__selector.active::after {
  top: 3.3125rem;
}

.slider__thumb {
  background-color: rgba(202, 255, 98, 0.5);
  border-radius: 50%;
  width: 2.8125rem;
  height: 2.8125rem;
  margin-right: 0.5rem;
  transform: scale(1);
  transition: ease-in 0.2s;
  transition-property: transform, background-color;
}

.init .slider__thumb {
  background-color: #caff62;
  transition-property: background-color;
  animation: pulse_animation 2s infinite ease-in;
}

.active .slider__thumb {
  background-color: rgba(202, 255, 98, 0.1);
}

.slider__submit {
  padding: 0;
  margin-right: 0.5rem;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-3.3125rem);
  transition: ease-in 0.2s;
  transition-property: opacity, visibility, transform;
}

.slider__submit.show {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.image-upload input {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.image-upload__preview {
  display: block;
  max-width: 100%;
  margin-bottom: 1rem;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loading-indicator, .loading-overlay__indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-indicator::after, .loading-overlay__indicator::after, .loading-indicator::before, .loading-overlay__indicator::before {
  content: '';
  width: 2.8125rem;
  height: 2.8125rem;
  margin: 0 0;
  display: block;
  background-color: #ffffff;
  border-radius: 50%;
  animation: pulse 2s ease-in-out infinite;
}

.loading-indicator::before, .loading-overlay__indicator::before {
  animation-delay: -1s;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  animation: fade-in 0.2s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.map {
  width: 80%;
}

.map__position {
  fill: #ffffff;
}

.map__position--active {
  fill: #caff62;
}

.map__position--disabled {
  fill: #5c3a1b;
  opacity: 0.16;
}

/* Button list / grid */
.image-grid {
  list-style: none;
  margin: 0 0 4rem 0;
  padding: 0 0 5rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.image-grid__item {
  width: 50%;
  margin-bottom: 0;
  padding: 0.125rem;
}

.image-grid__button {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  padding-top: 100%;
  color: #000000;
  text-decoration: none;
  background-color: #ffffff;
}

.image-grid__button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid #caff62;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
  transition-property: opacity visibility;
}

.image-grid__button--selected:after {
  opacity: 1;
  visibility: visible;
}

.image-grid__image {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

.photo-upload {
  width: 100%;
  min-height: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-upload .loading-indicator {
  margin-bottom: 1rem;
}

.photo-upload--preview {
  flex-grow: 1;
}

.photo-upload__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.photo-upload__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 1rem;
  width: 100%;
  flex-grow: 1;
  background: no-repeat center / contain;
}

.photo-upload__buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.photo-upload__buttons > * {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.photo-upload__skip {
  position: fixed;
  bottom: 1rem;
}

.photo-upload--preview .photo-upload__skip {
  position: static;
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.edit-tools {
  position: fixed;
  z-index: 10;
  right: 0.5rem;
  bottom: 0.5rem;
  background: #f8cd1c;
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 0.6rem;
  color: #ffffff;
}

.edit-tools .button {
  background: #c29d06;
  font-size: 1em;
  margin: 0.25rem;
  padding: 0.5rem 0.6rem;
  display: inline-block;
  min-width: auto;
  border-radius: 0.6rem;
}

.show-breakpoint {
  position: fixed;
  opacity: 0.7;
  z-index: 10;
  right: 0.5rem;
  bottom: 4rem;
  color: #ffffff;
  font-weight: bold;
}

.show-breakpoint > div {
  padding: 0.1em 0.5em;
  border-radius: 0.6rem;
}

.show-breakpoint .show-for-small-only {
  background: lightseagreen;
}

.show-breakpoint .show-for-medium-only {
  background: orange;
}

.show-breakpoint .show-for-large {
  background: red;
}

@keyframes blur-out {
  from {
    filter: blur(0);
  }
  to {
    filter: blur(4px);
  }
}

.screen {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.screen--ar .screen__skip-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.screen--ar .screen__skip-header a {
  margin-left: 1rem;
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(img/background.png) center/cover no-repeat;
}

.screen--blur .screen__background {
  filter: blur(4px);
  animation: blur-out 1s ease;
}

.screen--question .screen__background {
  background-image: url(img/background_r.png);
  background-position: bottom left;
}

.screen--ar.screen--iframe .screen__background {
  background: none;
}

.screen--image-grid .screen__background {
  background-image: url(img/background_wo_r.png);
}

.screen__icons {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 999;
}

.screen__icons a {
  display: block;
  background-color: #000000;
  font-size: 3rem;
  line-height: 1;
  text-decoration: none;
}

.screen__icons a:hover {
  background-color: #333333;
}

.screen__contents {
  position: relative;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 1.5rem 2rem;
  text-align: center;
}

.screen--start .screen__contents,
.screen--map .screen__contents,
.screen--shake .screen__contents,
.screen--swipe-instructions .screen__contents {
  justify-content: space-around;
}

.screen--image-grid .screen__contents {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  self-align: flex-start;
  justify-content: flex-start;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.screen--image-grid .screen__contents p {
  padding: 0 2rem;
}

.screen--ar .screen__contents {
  padding: 0;
  height: 100%;
}

.link--full {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.screen--shake .icon--shake {
  width: 4rem;
  margin-bottom: 1rem;
}

.screen--swipe-instructions .icon--swipe {
  width: auto;
  margin-bottom: 1rem;
}

.screen--ar iframe {
  height: 100%;
  width: 100%;
  border: none;
}

.question {
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.question__content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 1rem;
}

.screen--image-grid .question__content {
  justify-content: flex-start;
}

.messages {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(50, 50, 50, 0.75);
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.messages__message {
  text-align: center;
  padding: 0.5rem 1rem;
  color: #ffffff;
}

.messages__message p {
  margin-bottom: 0;
}

.agb {
  padding: 1rem;
  color: #000000;
}

.agb h1 {
  font-size: 1.25rem;
}

.agb h2 {
  font-size: 1.125rem;
}

.agb h3 {
  font-size: 1rem;
}

.agb p {
  font-family: "Helvetica Neue LT", sans-serif;
}

.agb ul {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
