$black: #000000;
$white: #ffffff;
$tundora: #424242;

// main colors
/* Blue */
$dodger-blue: #518dff;

/* Yellow */
$candlelight: #f8cd1c;

/* Gray */
$dusty-gray: #9b9b9b;

/* Green */
$canary: #caff62;
$punga: #5c3a1b;

/* Red */
$sunset-orange: #fc4f4f;
