@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

%loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;

  &::after,
  &::before {
    content: '';
    width: $icon-button-length;
    height: $icon-button-length;
    margin: 0 0;
    display: block;

    background-color: $white;
    border-radius: 50%;

    animation: pulse 2s ease-in-out infinite;
  }

  &::before {
    animation-delay: -1s;
  }
}

.loading-indicator {
  @extend %loading-indicator;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: transparentize($black, 0.25);

  animation: fade-in 0.2s ease-in;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading-overlay__indicator {
  @extend %loading-indicator;
}
