.navigator {
  display: flex;
  flex-flow: row nowrap;

  &.transition {
    transition: transform 0.4s ease-in-out;
  }
}

.navigator__page {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 1rem;

  border: 1px solid grey;
}
